import { apiSlice } from "./apiSlice";

type CustomWorkflow = {
  ID: string;
  Key: string;
};

type ListCustomWorkflowsResponse = {
  CustomWorkflows: CustomWorkflow[];
};

type CustomWorkflowExecution = {
  ID: string;
  Version: { ID: string };
  Status: {
    CurrentStepIndex: number | null;
    CurrentStepDescription: string | null;
    Progress: number | null;
    State: "PENDING" | "IN_PROGRESS" | "COMPLETE" | "ERROR";
  };
  CustomWorkflow: CustomWorkflow;
};

type ListCustomWorkflowExecutionsResponse = {
  CustomWorkflowExecutions: CustomWorkflowExecution[];
};

export const customWorkflowSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCustomWorkflows: build.query<ListCustomWorkflowsResponse, { versionId: string }>({
      query: ({ versionId }) => ({
        url: "/v2/custom-workflows",
        params: { version_id: versionId },
      }),
    }),
    getCustomWorkflowExecutions: build.query<ListCustomWorkflowExecutionsResponse, { versionId: string }>({
      query: ({ versionId }) => ({
        url: "/v2/custom-workflow-executions",
        params: { version_id: versionId },
      }),
      providesTags: (result, error, { versionId }) => (result ? [{ type: "CustomWorkflowExecutions", id: versionId }] : []),
    }),
    executeCustomWorkflow: build.mutation<any, { versionId: string; workflowId: string }>({
      query: ({ versionId, workflowId }) => ({
        url: `/v2/custom-workflow-executions`,
        method: "POST",
        body: { CustomWorkflowID: workflowId, VersionID: versionId },
      }),
      invalidatesTags: (result, error, { versionId }) => [{ type: "CustomWorkflowExecutions", id: versionId }],
    }),
  }),
});

/**
 * Endpoint hooks
 */
export const { useGetCustomWorkflowsQuery, useGetCustomWorkflowExecutionsQuery, useExecuteCustomWorkflowMutation } = customWorkflowSlice;
