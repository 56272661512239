import { FC } from "react";
import { useNavigate } from "react-router";

import { GoBackButton } from "ui";

import { PageCentred } from "fond/widgets/PageCentred";

import { useUpdateDocTitle } from "../hooks/useUpdateDocTitle";

import { ErrorPageBox } from "./errorPage.styles";

interface IProps {
  icon: React.ReactNode;
  title: string;
  heading: string;
  message: React.ReactNode | string;
}

const ErrorPage: FC<IProps> = ({ icon, title, heading, message }) => {
  useUpdateDocTitle(title);
  const navigate = useNavigate();

  const handleGoBackClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    navigate(-1);
    event.preventDefault();
  };

  return (
    <PageCentred
      title={heading}
      icon={
        <>
          <GoBackButton data-testid="error-page-back-button" onClick={handleGoBackClick} />
          {icon}
        </>
      }
    >
      <ErrorPageBox data-testid="error-page-message">{message}</ErrorPageBox>
    </PageCentred>
  );
};

export default ErrorPage;
