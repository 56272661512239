import { useState } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { OpenWith, Undo } from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";

import { selectCurrentAccount, selectCurrentAllocation, selectCurrentSubscription } from "fond/api";
import mixpanel from "fond/mixpanel";
import { repositionHubs, revertHubs } from "fond/project/redux";
import { SubscriptionStatus } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";
import { Actions, licenseCheck, subscriptionCheck } from "fond/utils/permissions";
import { ConfirmModal, useStackedNavigationContext } from "fond/widgets";
import { LockIcon } from "fond/widgets/Navigation/navigation.styles";

interface IProps {
  hasEditedEquipment: boolean;
}

const SolveEditing: React.FC<IProps> = ({ hasEditedEquipment }: IProps) => {
  const { open } = useStackedNavigationContext();
  const dispatch = useAppDispatch();
  const currentAllocation = useSelector(selectCurrentAllocation);
  const currentAccount = useSelector(selectCurrentAccount);
  const currentSubscription = useSelector((state) => currentAccount && selectCurrentSubscription(state, currentAccount.ID));
  const isAllowed =
    licenseCheck(currentAllocation, Actions.PLANNER_PROJECT_EDIT) &&
    subscriptionCheck(currentSubscription, Actions.PLANNER_PROJECT_EDIT) &&
    currentSubscription?.Status === SubscriptionStatus.Active;

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const onResetEquipmentClick = () => {
    mixpanel.track("Clicked reset moved equipment");
    dispatch(revertHubs());
    setConfirmationModalOpen(false);
  };

  const onMoveEquipmentClick = () => {
    mixpanel.track("Clicked move equipment");
    dispatch(repositionHubs());
    setConfirmationModalOpen(false);

    open("draw");
  };

  return (
    <>
      {confirmationModalOpen && (
        <ConfirmModal
          open
          content="Are you sure you want to reset all moved hubs?"
          onConfirm={onResetEquipmentClick}
          onCancel={() => setConfirmationModalOpen(false)}
        />
      )}
      <Box mt={2}>
        <Typography variant="h6" fontSize={14}>
          Make refinements
        </Typography>
        <Box display="flex" justifyContent="center" mt={0.5} mb={0.5}>
          {!isAllowed && <LockIcon />}
        </Box>
        <Typography variant="body3" textAlign={isAllowed ? undefined : "center"}>
          {isAllowed
            ? "The design has been successfully generated. To make modifications select an action below:"
            : "You need a license/subscription to make refinements."}
        </Typography>
        <Box my={2}>
          <Divider />
          <Button
            data-testid="reposition-hubs-button"
            fullWidth
            sx={{ my: 0.5, justifyContent: "left" }}
            startIcon={<OpenWith sx={{ height: 20, color: (theme) => theme.palette.primary.main }} />}
            onClick={onMoveEquipmentClick}
            disabled={!isAllowed}
          >
            <Typography color={(theme) => theme.palette.biarri.secondary.darkGrey} fontWeight={500} fontSize={13}>
              Move hubs
            </Typography>
          </Button>
          <Divider />
          {hasEditedEquipment && isAllowed && (
            <>
              <Button
                data-testid="reset-equipment-button"
                fullWidth
                sx={{ my: 0.5, justifyContent: "left", color: (theme) => theme.palette.biarri.secondary.darkGrey, fontWeight: 500, fontSize: 13 }}
                startIcon={<Undo sx={{ height: 20, color: (theme) => theme.palette.primary.main }} />}
                onClick={() => setConfirmationModalOpen(true)}
                disabled={!isAllowed}
              >
                Reset moved hubs
              </Button>
              <Divider />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SolveEditing;
