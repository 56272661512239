import React, { useCallback, useState } from "react";
import { Lock } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Typography } from "@mui/material";
import classNames from "classnames";

import { Project } from "fond/types";
import { useAppSelector } from "fond/utils/hooks";
import * as localStorage from "fond/utils/localStorage";
import { Actions, permissionCheck } from "fond/utils/permissions";
import { NonIdealState, SpinningCircle, StackedNavigation } from "fond/widgets";

import BoundaryDrawPanel from "./BoundaryDrawPanel";
import BoundaryPanel from "./BoundaryPanel";
import ConnectionsDrawPanel from "./ConnectionsDrawPanel";
import ConnectionsPanel from "./ConnectionsPanel";
import GenerateDesignPanel from "./GenerateDesignPanel";
import { getAccordionStepClassSets, getStartingIndex } from "./helper";
import { useGetDesignLayers } from "./useGetDesignLayers";

const cityPlannerStackedNavigationPanels: Array<{ id: string; name: string; component: React.ReactNode; useDefaultHeader?: boolean }> = [
  {
    id: "boundaryDraw",
    name: "Boundary",
    component: <BoundaryDrawPanel />,
    useDefaultHeader: false,
  },
  {
    id: "connectionsDraw",
    name: "Connections",
    component: <ConnectionsDrawPanel />,
    useDefaultHeader: false,
  },
];

interface SmallDesignPanelProps {
  /**
   * The project currently being viewed / edited
   */
  project: Project;
  /**
   * Flag indicating if the project is read only
   */
  readOnly: boolean;
}

const SmallDesignPanel: React.FC<SmallDesignPanelProps> = ({ project, readOnly }: SmallDesignPanelProps) => {
  const versionId = useAppSelector((state) => state.project.versionId);
  const designLayers = useGetDesignLayers(versionId);
  const stepClassSets = getAccordionStepClassSets(designLayers);
  const accordionLocalStorageKey = `state.project.small[${project?.ID}].accordion`;
  const [activeIndex, setActiveIndex] = useState(
    localStorage.getItem(accordionLocalStorageKey)?.activeIndex ?? (project ? getStartingIndex(designLayers) : 0)
  );
  const setAccordionIndex = useCallback(
    (index: number) => {
      setActiveIndex(index);
      localStorage.setItem(accordionLocalStorageKey, { activeIndex: index });
    },
    [accordionLocalStorageKey]
  );

  /**
   * Handles the expanding of the clicked according item if that item is not disabled.
   */
  const handleOnClickIndex = (index: number) => {
    if (index !== activeIndex) {
      const maxIndex = getStartingIndex(designLayers);
      if (index <= maxIndex) {
        setAccordionIndex(index);
      }
    }
  };

  const accordionPanes = [
    {
      header: "Base data",
      id: "boundary",
      content: <BoundaryPanel project={project} />,
    },
    {
      header: "Connection Points",
      id: "connections",
      rightAdornment: <Chip label="optional" size="small" />,
      content: <ConnectionsPanel project={project} />,
    },
    {
      header: "Design",
      id: "design",
      content: <GenerateDesignPanel />,
    },
  ];

  if (!project) return null;

  return (
    <Box className="auto-design-panel" data-testid="city-planner-design-panel">
      {!permissionCheck(project.Permission?.Level, Actions.PROJECT_EDIT) ? ( // if user has access and no license, more granular "locks" are applied per component
        <NonIdealState size="small" icon={<Lock />} title="View only access" description="You only have view access to this project" />
      ) : (
        <Box className="auto-design-panel__inner">
          <StackedNavigation
            rootComponent={
              <div className={classNames("accordion", "customScrollbars")}>
                {accordionPanes.map((pane, i) => {
                  return (
                    <Accordion key={`${pane.id}_title`} data-testid={`accordion-pane-${pane.id}`} expanded={activeIndex === i} elevation={0}>
                      <AccordionSummary
                        onClick={() => handleOnClickIndex(i)}
                        style={{
                          minHeight: "auto",
                          margin: 0,
                          display: "flex",
                          flexDirection: "row",
                        }}
                        disabled={stepClassSets[i].disabled}
                        className={classNames("title", { ...stepClassSets[i] }, { active: activeIndex === i })}
                      >
                        <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                          <Box p="5px">
                            <div className="workflow-step-number-circle">
                              <div className="workflow-step-number-circle__inner">
                                <div className="workflow-step-number-circle__number">{i + 1}</div>
                                {stepClassSets[i].loading && (
                                  <div className="workflow-step-number-circle__spinning-circle-container">
                                    <SpinningCircle />
                                  </div>
                                )}
                              </div>
                            </div>
                          </Box>
                          <Typography variant="body1" className="accordion-panel__title">
                            {pane.header}
                          </Typography>
                        </Box>
                        <Box>{pane.rightAdornment}</Box>
                      </AccordionSummary>
                      <AccordionDetails key={`${pane.id}_content`} className="accordion-panel__content" style={{ margin: 0 }}>
                        {pane.content}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </div>
            }
            screens={cityPlannerStackedNavigationPanels}
          />
        </Box>
      )}
    </Box>
  );
};

export default SmallDesignPanel;
