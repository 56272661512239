import React, { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { Undo } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button, Divider, Typography } from "@mui/material";
import { FeatureCollection, MultiPolygon } from "geojson";

import { useGetMultiProjectQuery, useUpdateMultiProjectMutation } from "fond/api";
import DrawHandler from "fond/draw/DrawHandler";
import { HistoryContext, useHistoryContext } from "fond/history";
import { MapContext } from "fond/map/MapProvider";
import { polygonDrawingConfig } from "fond/map/Toolbar";
import { combine, feature as toFeature, featureCollection } from "fond/turf";
import { Store } from "fond/types";
import { isAnyPolygon } from "fond/types/geojson";
import { useStackedNavigationContext } from "fond/widgets";
import StackedNavigationHeader from "fond/widgets/StackedNavigation/StackedNavigationHeader";

const BoundaryDrawPanel: React.FC = () => {
  const multiProjectId = useSelector((state: Store) => state.project.projectId);
  const { data: multiProject } = useGetMultiProjectQuery(multiProjectId);
  const { goBack, clear } = useStackedNavigationContext();
  const { clear: clearHistory } = useHistoryContext<FeatureCollection>(HistoryContext);
  const { drawControl, setDrawMode } = useContext(MapContext);
  const [updateMultiProject] = useUpdateMultiProjectMutation();

  /**
   * Get the multiproject Boundary & convert it to multiple polygons ready to load as
   * the initial draw features.
   */
  const initialFeatures = useMemo(
    () => (multiProject?.Boundary ? [toFeature(multiProject.Boundary, { id: multiProjectId }, { id: multiProjectId })] : []),
    [multiProject?.Boundary, multiProjectId]
  );

  const handleBack = () => {
    goBack();
  };

  const onReset = () => {
    drawControl.current.set(featureCollection(initialFeatures));
    clearHistory();
  };

  /**
   * Handles importing the currently draw features and updating the multiProject
   * with the new Boundary.
   */
  const handleOnImport = () => {
    const features = drawControl.current.getAll().features.filter(isAnyPolygon);

    // Combine all Polygons into a single MultiPolygon which is expected by the backend
    const collection = features.length > 0 ? (combine(featureCollection(features)) as FeatureCollection<MultiPolygon>) : null;

    updateMultiProject({ ID: multiProjectId, Boundary: collection?.features[0].geometry || null }).then(() => {
      setDrawMode("no_feature");
      clear();
    });
  };

  return (
    <>
      <DrawHandler initialFeatures={initialFeatures} source="multiProject-source" autoClip config={polygonDrawingConfig} />

      <StackedNavigationHeader onBackButtonClick={handleBack} title="Draw on map" />
      <Box data-testid="draw-panel" padding={1}>
        <Alert severity="info" data-testid="layer-alert">
          <AlertTitle sx={{ mb: 0 }}>Draw city boundary</AlertTitle>
        </Alert>
        <Divider sx={{ mt: 3 }} />
        <Button fullWidth sx={{ my: 0.5, justifyContent: "left" }} startIcon={<Undo sx={{ height: 20 }} />} onClick={onReset}>
          <Typography color={(theme) => theme.palette.common.black} fontWeight={500} fontSize={13}>
            Reset
          </Typography>
        </Button>
        <Divider />
        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
          <Button color="primary" size="small" onClick={handleBack}>
            Cancel
          </Button>
          <Button variant="contained" size="small" onClick={handleOnImport} sx={{ ml: 1, px: 2 }} data-testid="finish-button">
            Finish
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default BoundaryDrawPanel;
