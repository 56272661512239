import * as React from "react";
import { useEffect } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Link, Typography } from "@mui/material";

import { PageCentred } from "fond/widgets/PageCentred";

const PaymentConfirmation: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // can only be redirected from CheckoutForm after purchase complete
    if (!location.state?.Invoice) {
      navigate("/workspace");
    }
  }, [location.state?.Invoice, navigate]);

  return (
    <PageCentred windowTitle="Payment confirmation">
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Typography variant="h3" margin={3} data-testid="payment-confirmed-header">
          Payment confirmed
        </Typography>
        <CheckCircleIcon color="primary" fontSize="large" />
        <Typography fontSize="1rem" margin={2}>
          Thank you for your purchase!
        </Typography>
        <Typography textAlign="center">You will receive a confirmation email with the details of our purchase.</Typography>

        <Typography variant="caption" marginTop={3}>
          Go to{" "}
          <Link component={RouterLink} to="/settings/account" fontWeight="bold" data-testid="go-to-account-settings">
            Account settings
          </Link>{" "}
          to view new details
        </Typography>
      </Box>
    </PageCentred>
  );
};

export default PaymentConfirmation;
