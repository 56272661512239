import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { isEqual, omit, pick } from "lodash";
import { createSelectorCreator, defaultMemoize } from "reselect";

import { loadProject } from "fond/project/redux";
import {
  AppThunkDispatch,
  Architecture,
  Configuration,
  ConfigurationResponse,
  ConfigurationUpsert,
  CTSBinRangesResponse,
  GroupConfig,
  GroupConfigHydrated,
  ImportStatus,
  ProjectFiles,
  ProjectStatus,
  Store,
  Style,
  Version,
  VersionImportStatus,
  VersionLegacyExport,
  VersionV2,
} from "fond/types";
import {
  FilterConfiguration,
  LayerConfig,
  LayerConfigHydrated,
  LayerStyle,
  SublayerConfig,
  SublayerConfigHydrated,
} from "fond/types/ProjectLayerConfig";
import { pickIDs } from "fond/utils";
import { selectGroupsFromConfig, selectLayersFromConfig, selectStylesFromConfig } from "fond/utils/configurations";
import { negativeFilterConfiguration } from "fond/utils/mapbox";

import { apiSlice } from "./apiSlice";
import { orderLayersByConfiguration } from "./draftSlice";

type GetVersionsResponse = {
  Versions: VersionV2[];
};

/*
 * Payload required to update an existing version architecture.
 *
 * Note that ID is either the ID of a version architecture or a base architecture.
 */
type UpdateVersionArchitecture = Partial<
  Pick<
    Architecture,
    | "Name"
    | "Description"
    | "Demand"
    | "NumberOfTiers"
    | "HasAttemptedSave"
    | "IsConfigured"
    | "IsFlexNap"
    | "BOM"
    | "DefaultPlacement"
    | "Tier1"
    | "Tier2"
    | "Tier3"
    | "ID"
  >
>;

export const versionConfigEntityAdapter = createEntityAdapter<GroupConfig | LayerConfig | SublayerConfig | LayerStyle>({
  selectId: (item) => item.ID,
});

const initialConfigState: Configuration = {
  ID: "",
  Key: "",
  SourceID: "",
  MapChildren: [],
  Data: versionConfigEntityAdapter.getInitialState(),
  Type: <const>"MapLayerConfig",
};

export const transformVersionResponse = (version: VersionV2): Version => ({
  ...version,
  Creator: { ...version.Creator, Rank: null },
  LastModifiedBy: { ...version.LastModifiedBy, Rank: null },
  Project: version.Project?.ID ?? null,
  Parent: version.Parent?.ID ?? null,
  MapLayerConfigID: version.RootConfigurationID,
  AddressTypeField: version?.AddressTypeField ?? null,
});

export const inverseTransformVersionResponse = (version: Version): VersionV2 => ({
  ...version,
  Creator: omit(version.Creator, "Rank"),
  LastModifiedBy: omit(version.LastModifiedBy, "Rank"),
  Project: { ID: version.Project },
  Parent: version.Parent ? { ID: version.Parent } : null,
  RootConfigurationID: version.MapLayerConfigID,
});

export const transformStyleResponse = (style: Style): LayerStyle => {
  const { ID, GlobalPosition, ConfigurationID, ConfigurationType, Name, Position, MapboxStyle, ...RawStyles } = style;

  return {
    ID: ID,
    Name: Name,
    GlobalPosition: GlobalPosition || 0,
    ConfigurationID: ConfigurationID,
    ConfigurationType: ConfigurationType,
    Position: Position,
    MapboxStyle: MapboxStyle,
    RawStyles: RawStyles,
    Type: <const>"STYLE",
  };
};

/**
 * Flattens the backend nested data structure to allow for easier manipulation.
 */
export const transformGetVersionConfigResponse = (response: ConfigurationResponse): Configuration => {
  const { GroupConfigurations, LayerConfigurations, StyleConfigurations, SublayerConfigurations, MapChildren, ...rest } = response;

  const layerConfigs = LayerConfigurations.map(transformLayerConfigResponse);
  const sublayerConfigs = SublayerConfigurations.map((sublayer) => transformSublayerResponse(sublayer, layerConfigs));

  let data = versionConfigEntityAdapter.setAll(versionConfigEntityAdapter.getInitialState(), [
    ...StyleConfigurations.map(transformStyleResponse),
    ...layerConfigs,
    ...sublayerConfigs,
    ...GroupConfigurations.map(transformGroupConfigResponse),
  ]);

  data = versionConfigEntityAdapter.upsertMany(
    data,
    layerConfigs.reduce<SublayerConfig[]>((value, layer) => {
      const other = buildSublayerFilterConfigurationByParentId(layer.ID, data.entities);
      if (other) value.push(other);
      return value;
    }, [])
  );
  const configuration: Configuration = {
    ...rest,
    Data: data,
    MapChildren: pickIDs(MapChildren),
    Type: "MapLayerConfig",
  };

  return configuration;
};

/**
 * Convert the SublayerConfig to the flat structure by replacing the collection
 * of Styles to a collection of IDs instead.
 */
export const transformSublayerResponse = (
  sublayer: SublayerConfigHydrated,
  entities: Array<LayerConfigHydrated | LayerConfig | GroupConfig | SublayerConfig | LayerStyle | undefined>
): SublayerConfig => {
  const { Styles, ...rest } = sublayer;
  // The Key and GeometryType are copied from the parent LayerConfig
  const parentLayerConfig = entities.find((entity) => entity?.ID === sublayer.ParentID) as LayerConfig;

  return {
    ...rest,
    GeometryType: parentLayerConfig?.GeometryType,
    Key: parentLayerConfig?.Key || "",
    Styles: pickIDs(Styles),
  };
};

export const buildSublayerFilterConfigurationByParentId = (
  id: string,
  entities: Record<string, LayerConfig | GroupConfig | SublayerConfig | LayerStyle | undefined>
): SublayerConfig | null => {
  const parentLayerConfig = entities[id] as LayerConfig;
  const siblings: SublayerConfig[] = [];

  const sublayer: SublayerConfig | null = parentLayerConfig?.Children.reduce<SublayerConfig | null>((value, childId) => {
    const child = entities[childId] as SublayerConfig;
    if (child.FilterConfiguration?.Type === "other") {
      return child;
    } else {
      siblings.push(child);
      return value;
    }
  }, null);

  if (sublayer) {
    return {
      ...sublayer,
      Styles: [...sublayer.Styles],
      FilterConfiguration: {
        ...sublayer.FilterConfiguration,
        Mapbox: negativeFilterConfiguration(siblings),
      } as FilterConfiguration,
    };
  }

  return null;
};

/**
 * Convert the LayerConfig to the flat structure by replacing the collection
 * of Children & Styles to a collection of IDs instead.
 */
export const transformLayerConfigResponse = (layer: LayerConfigHydrated): LayerConfig => {
  const { Children, Styles, ...rest } = layer;

  return {
    ...rest,
    Children: pickIDs(Children),
    Styles: pickIDs(Styles),
  };
};

export const transformGroupConfigResponse = (group: GroupConfigHydrated): GroupConfig => {
  const { Children, ...rest } = group;

  return {
    ...rest,
    Children: pickIDs(Children),
  };
};

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const versionsAdapter = createEntityAdapter<Version>({
  selectId: (entity: Version): string => entity.ID,
});
const versionsInitialState = versionsAdapter.getInitialState();

/**
 * Versions API Slice
 */
export const versionsSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getVersion: build.query<Version, string>({
      query: (versionId: string) => `/v2/versions/${versionId}`,
      transformResponse: transformVersionResponse,
      providesTags: (result) => (result ? [{ type: "Version", id: result.ID }] : []),
    }),
    getVersionConfig: build.query<Configuration, string>({
      query: (versionId: string) => `/v2/versions/${versionId}/root-configuration`,
      transformResponse: transformGetVersionConfigResponse,
    }),
    getVersionCostBinRanges: build.query<CTSBinRangesResponse, string>({
      query: (versionId: string) => `/v2/versions/${versionId}/cost-bin-ranges`,
    }),
    getVersions: build.query({
      query: (projectId: string) => `/v2/projects/${projectId}/versions`,
      transformResponse: (response: GetVersionsResponse) =>
        versionsAdapter.setAll(versionsInitialState, response.Versions.map(transformVersionResponse)),
      providesTags: (result, error, arg) =>
        result
          ? [...result.ids.map((id) => ({ type: "Version" as const, id: id })), { type: "Versions", id: arg }]
          : [{ type: "Versions", id: "LIST" }],
    }),
    updateVersion: build.mutation<Version, Pick<Version, "ID" | "Name">>({
      query: ({ ID, ...version }) => ({
        url: `/v2/versions/${ID}`,
        method: "PATCH",
        body: version,
      }),
      transformResponse: transformVersionResponse,
      invalidatesTags: (result, error, arg) => [{ type: "Version", id: arg.ID }],
    }),
    createVersion: build.mutation<Version, { projectId: string; versionId: string; name: string; method?: "copy_design" | null }>({
      query: ({ versionId, name, method }) => ({
        url: "/v2/versions",
        method: "POST",
        body: {
          ParentID: versionId,
          Name: name,
          Method: method ? { Type: method } : null,
        },
      }),
      transformResponse: transformVersionResponse,
      invalidatesTags: (result, error, arg) => [{ type: "Versions", id: arg.projectId }],
    }),
    deleteVersion: build.mutation<void, Pick<Version, "ID" | "Project">>({
      query: ({ ID }) => ({
        url: `/v2/versions/${ID}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Versions", id: arg.Project }],
    }),
    getImportStatus: build.query<VersionImportStatus, { versionId?: string; status?: ImportStatus[] }>({
      query: ({ versionId, status }) => {
        if (Boolean(versionId) === Boolean(status)) {
          throw new Error("Querying import status requires either versionId or list of status.");
        }
        return {
          url: `/v2/import-status`,
          params: { version_id: versionId, status: status?.join(",") },
        };
      },
    }),
    getVersionLegacyExport: build.query<ProjectFiles, string>({
      query: (versionId: string) => `/v2/versions/${versionId}/legacy-export`,
      // VersionLegacyExport has a SpliceTableExists field, but we don't actually care about it.
      transformResponse: (response: VersionLegacyExport) => response.Layers,
    }),
    getVersionStatus: build.query<ProjectStatus, string>({
      query: (versionId: string) => `/v2/versions/${versionId}/status`,
      providesTags: (result, error, versionId) => (result ? [{ type: "VersionStatus", id: versionId }] : []),
    }),
    cancelVersionWorkflow: build.mutation<void, string>({
      query: (versionId) => ({
        url: `/v2/versions/${versionId}/workflow`,
        method: "PATCH",
        body: {
          Status: "Cancelled",
        },
      }),
      invalidatesTags: (result, error, versionId) => [{ type: "VersionStatus", id: versionId }],
    }),
    updateVersionArchitecture: build.mutation<Architecture, { versionId: string; architecture: UpdateVersionArchitecture }>({
      query: ({ versionId, architecture }) => ({
        url: `/v2/versions/${versionId}/architecture`,
        method: "PATCH",
        // The internal architecture object is filled with all sorts of fun fields.
        // Ensure we're sending only those fields we know we're allowed to send.
        body: pick(architecture, [
          "Name",
          "Description",
          "Demand",
          "NumberOfTiers",
          "HasAttemptedSave",
          "IsConfigured",
          "IsFlexNap",
          "BOM",
          "DefaultPlacement",
          "Tier1",
          "Tier2",
          "Tier3",
          "ID",
        ]),
      }),
      invalidatesTags: (result, error, arg) => [{ type: "VersionStatus", id: arg.versionId }],
      onQueryStarted: async ({ versionId, architecture }, { dispatch, queryFulfilled }) => {
        // Do a pessimistic update of the getVersion response to avoid refetching the version response.
        const { data: updatedArchitecture } = await queryFulfilled;
        dispatch(
          versionsSlice.util.updateQueryData("getVersion", versionId, (draft) => {
            draft.Architecture = updatedArchitecture;
          })
        );
      },
    }),
    reapplyDemandModel: build.mutation<any, { projectId: string; versionId: string }>({
      query: ({ versionId }) => ({
        url: `/v2/versions/${versionId}/reapply-demand-model`,
        method: "PATCH",
      }),
      onQueryStarted: async ({ projectId }, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(loadProject({ uuid: projectId, forceRefetch: true }));
      },
    }),
  }),
});

/**
 * Injects into the version config the Cost to Serve information
 */
export const injectIntoVersionConfig = async (dispatch: AppThunkDispatch, versionId: string, upsert: ConfigurationUpsert): Promise<void> => {
  const result = dispatch(versionsSlice.endpoints.getVersionConfig.initiate(versionId));
  const data = (await result)?.data;
  result.unsubscribe();

  if (data) {
    const newConfig: Configuration = {
      ...data,
      MapChildren: [...pickIDs(upsert.filter((entity) => entity.Type === "GROUP")), ...data.MapChildren],
      Data: versionConfigEntityAdapter.upsertMany(data.Data, upsert),
    };

    await dispatch(versionsSlice.util.upsertQueryData("getVersionConfig", versionId, newConfig));
  }
};

/**
 * Endpoint Hooks
 */
export const {
  useCreateVersionMutation,
  useGetVersionQuery,
  useLazyGetVersionQuery,
  useGetVersionConfigQuery,
  useGetVersionsQuery,
  useLazyGetVersionCostBinRangesQuery,
  useUpdateVersionMutation,
  useDeleteVersionMutation,
  useGetImportStatusQuery,
  useGetVersionStatusQuery,
  useUpdateVersionArchitectureMutation,
  useReapplyDemandModelMutation,
} = versionsSlice;

/**
 * Selectors
 */

export const selectByVersionId = createSelector(
  [(state) => state, (state: Store, args: { projectId: string; versionId: string }) => args],
  (state: Store, { projectId, versionId }) => {
    const data = createSelector(versionsSlice.endpoints.getVersions.select(projectId), (versionsResult) => versionsResult.data)(state);
    return data?.entities[versionId];
  }
);

export const selectVersionsByProjectId = createSelector(
  [(state) => state, (state: Store, projectId: string) => projectId],
  (state: Store, projectId) => {
    const data = createSelector(versionsSlice.endpoints.getVersions.select(projectId), (versionsResult) => versionsResult.data)(state);
    return data?.ids.map((id) => data.entities[id] as Version);
  }
);

export const selectVersionConfig = createDeepEqualSelector(
  [(state) => state, (state: Store, versionId?: string) => versionId],
  (state: Store, versionId) => {
    if (!versionId) return initialConfigState;
    const { data } = createSelector(versionsSlice.endpoints.getVersionConfig.select(versionId), (versionData) => versionData)(state);
    return data ?? initialConfigState;
  }
);

/**
 * Returns a flat list of layers and sublayers in the correct order based
 * on their root, group & layer ordering.
 *
 */
export const selectAllVersionLayerConfigsInOrder = createSelector(selectVersionConfig, (configuration) => orderLayersByConfiguration(configuration));

export const { selectAll: selectAllVersionConfigs } = versionConfigEntityAdapter.getSelectors(
  (state: Store, versionId = undefined) => selectVersionConfig(state, versionId ?? state.project.versionId).Data
);

export const selectAllVersionLayers = createSelector(selectVersionConfig, selectLayersFromConfig);

export const selectAllVersionGroupConfigs = createSelector(selectVersionConfig, selectGroupsFromConfig);

export const selectAllVersionStyles = createSelector(selectVersionConfig, selectStylesFromConfig);

/**
 * Generate a dictionary that contains the mapping between LayerKey and Label.
 */
export const selectLayerLabelByLayerKey = createSelector(selectVersionConfig, (config) => {
  let configsList: { [key: string]: string } = {};
  Object.values(config.Data.entities).forEach((item) => {
    if (item && item.Type === "LAYER" && item.Key && item.SubType !== "COST_TO_SERVE") {
      configsList[item.Key] = item.Label;
    }
  });
  return configsList;
});

export const selectVersionMlcId = createSelector([(state) => state, (state: Store) => state.project.versionId], (state: Store, versionId) => {
  return createSelector(versionsSlice.endpoints.getVersion.select(versionId), (versionResult) => versionResult.data?.MapLayerConfigID || "")(state);
});

export const selectCurrentVersionStatus = createSelector(
  [(state) => state, (state: Store) => state.project?.versionId],
  (state: Store, versionId): ProjectStatus | undefined => {
    if (!versionId) return undefined;
    const { data } = createSelector(versionsSlice.endpoints.getVersionStatus.select(versionId), (versionData) => versionData)(state);
    return data;
  }
);

export const refetchVersionStatus = async (dispatch: AppThunkDispatch, versionId: string): Promise<ProjectStatus | undefined> => {
  const result = dispatch(versionsSlice.endpoints.getVersionStatus.initiate(versionId, { forceRefetch: true }));
  const data = (await result)?.data;
  result.unsubscribe();
  return data;
};

export const selectLayerVersionImportStatus = createSelector(
  [(state) => state, (state: Store, args: { versionId: string; layerId: string }) => args],
  (state: Store, { versionId, layerId }): ImportStatus | undefined => {
    if (!versionId) return undefined;
    const { data } = createSelector(versionsSlice.endpoints.getImportStatus.select({ versionId }), (versionData) => versionData)(state);
    return data?.Layers.find(({ LayerKey }) => LayerKey === layerId)?.ImportStatus.Status;
  }
);
