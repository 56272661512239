import React from "react";

import { PageContent } from "fond/widgets";

import { Page } from "../constants";
import Navigation from "../Navigation";
import { reportWindowTitle } from "../util";

import CreateReportForm from "./CreateForm";

const CreateReportPage: React.FC = () => {
  return (
    <PageContent
      windowTitle={reportWindowTitle("Settings")}
      title="Settings"
      mainContent={<CreateReportForm />}
      navigation={<Navigation page={Page.SETTINGS} isCreatingReport />}
      testId="create-report-page"
    />
  );
};

export default CreateReportPage;
