import { useState } from "react";
import * as React from "react";
import { Form } from "react-final-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import { Email as EmailIcon, Lock } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button } from "@mui/material";

import { LoadingButton } from "ui";

import { INVITATION_ID_KEY, RETURN_URL } from "fond/constants";
import { TextField } from "fond/form/fields";
import mixpanel from "fond/mixpanel";
import { CognitoUser } from "fond/types";
import { CognitoResponseCode, getCognitoResponseHelperText, isUserNotConfirmed } from "fond/utils";
import { useQueryParams } from "fond/utils/hooks";
import { compose, required, validEmailFormat } from "fond/utils/validation";
import { Footer, SystemErrorDialog, VisibilityAdornment } from "fond/widgets";

import { Link } from "./signInForm.styles";

interface IFormData {
  email: string;
  password: string;
}

interface IProps {
  /**
   * a function that can be called when a user must select a new password.
   * Will cause a different form to be rendered from the parent component.
   */
  onNewPasswordRequired: (user: CognitoUser) => void;
}

/**
 * A sign in form which uses @aws-amplify/auth. In the event that a user
 * requires a new password, the props onNewPasswordRequired function is called
 * and the parent component will render the NewPasswordForm instead.
 */
const SignInForm: React.FC<IProps> = ({ onNewPasswordRequired }: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [hidePassword, setHidePassword] = useState(true);
  const [linkSent, setLinkSent] = useState(false);
  const [cognitoResponseCode, setCognitoResponseCode] = useState<string | null>(null);
  const returnUrl = useQueryParams(RETURN_URL) || "/";
  const invitationId = useQueryParams(INVITATION_ID_KEY);

  const handleOnSubmit = async ({ email, password }: IFormData) => {
    return new Promise((resolve) => {
      const lowercaseEmail = email.toLowerCase();
      Auth.signIn(lowercaseEmail, password)
        .then((user: CognitoUser) => {
          if (user.challengeName === CognitoResponseCode.NEW_PASSWORD_REQUIRED) {
            onNewPasswordRequired(user);
          } else {
            mixpanel.identify(lowercaseEmail);
            mixpanel.track("Signed in");
            /*
             * This should have been set when the user sign up. However we will have
             * some users who existed before we integrated mixpanel.
             */
            mixpanel.setProfile({
              $email: lowercaseEmail,
            });

            navigate(returnUrl, { state: { ...location.state, invitationId: invitationId } });
          }
          resolve("success");
        })
        .catch((err) => {
          setCognitoResponseCode(err.code);
          resolve({
            email: getCognitoResponseHelperText(err.code),
            password: getCognitoResponseHelperText(err.code),
          });
        });
    });
  };

  const handleResendVerificationEmail = (email: string) => {
    Auth.resendSignUp(email.toLowerCase());
    setLinkSent(true);
  };

  return (
    <>
      {location.state?.invitationId && (
        <Alert severity="info" sx={{ mb: 4 }}>
          You need to sign in first to continue
        </Alert>
      )}
      <Form<IFormData>
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={handleOnSubmit}
        render={({ handleSubmit, submitting, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box>
                <TextField
                  name="email"
                  label="Email"
                  validate={compose(required, validEmailFormat)}
                  InputProps={{
                    startAdornment: <EmailIcon color="action" />,
                  }}
                />
                <Box sx={{ mt: 2 }}>
                  <TextField
                    name="password"
                    label="Password"
                    autoComplete="off"
                    type={hidePassword ? "password" : "text"}
                    validate={required}
                    InputProps={{
                      startAdornment: <Lock color="action" />,
                      endAdornment: <VisibilityAdornment isHidden={hidePassword} update={setHidePassword} />,
                    }}
                  />
                </Box>
                <Box sx={{ textAlign: "right", mb: 2 }}>
                  <Link to="/forgotpassword" data-testid="forgotten-password-link">
                    Forgot password?
                  </Link>
                </Box>
                <Box>
                  {linkSent ? (
                    <Alert severity="success" sx={{ mt: 4 }}>
                      <AlertTitle>Verification email sent!</AlertTitle>
                      Click on the link we've emailed you to verify your email.
                    </Alert>
                  ) : (
                    isUserNotConfirmed(cognitoResponseCode) && (
                      <Button
                        data-testid="resend-verification-email-button"
                        fullWidth
                        sx={{ mt: 1 }}
                        onClick={() => handleResendVerificationEmail(values.email)}
                      >
                        Resend the verification email
                      </Button>
                    )
                  )}
                </Box>
                <Box sx={{ mt: 4 }}>
                  <LoadingButton
                    loading={submitting}
                    data-testid="sign-in-button"
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    LOG IN
                  </LoadingButton>
                </Box>
                <Box sx={{ mt: 4 }}>
                  <Footer />
                </Box>
              </Box>
            </form>
          );
        }}
      />

      <SystemErrorDialog
        open={cognitoResponseCode === CognitoResponseCode.NETWORK_ERROR || cognitoResponseCode === CognitoResponseCode.UNKNOWN_ERROR}
        networkError={cognitoResponseCode === CognitoResponseCode.NETWORK_ERROR}
        onClose={() => {
          setCognitoResponseCode(null);
        }}
      />
    </>
  );
};

export default SignInForm;
