import React from "react";
import { Redo } from "@mui/icons-material";

import { HistoryContext, useHistoryContext } from "fond/history";

import { IButtonProps } from "./Toolbar";
import ToolbarButton from "./ToolbarButton";

const RedoButton: React.FC<IButtonProps> = ({ mode }: IButtonProps) => {
  const { canRedo, redo } = useHistoryContext(HistoryContext);
  const isDisabled = !canRedo || (!!mode && !["simple_select", "direct_select"].includes(mode));

  const handleOnClick = () => {
    redo();
  };

  return (
    <ToolbarButton title="Redo" onClick={handleOnClick} disabled={isDisabled}>
      <Redo />
    </ToolbarButton>
  );
};

export default RedoButton;
