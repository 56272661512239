import { CSSProperties, forwardRef, memo, useCallback, useEffect, useRef, useState } from "react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddComment,
  Block,
  Check,
  Close,
  Directions,
  DoubleArrow,
  LabelImportant,
  LinearScale,
  LocationOn,
  MoreHoriz,
  MoreVert,
  RadioButtonUnchecked,
  RemoveCircleOutline,
  TrendingFlat,
} from "@mui/icons-material";
import { Alert, Box, IconButton, Snackbar, Tooltip, Typography } from "@mui/material";
import { blue, grey, orange, red } from "@mui/material/colors";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import dayjs from "dayjs";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { useGetPermissionsQuery } from "fond/api";
import { DragContext } from "fond/map/MapPopup";
import AddCommentForm, { IFormData } from "fond/project/comments/AddCommentForm";
import CommentMenu from "fond/project/comments/CommentMenu";
import { unselectComment } from "fond/project/redux";
import { addReply, resolveComment, selectComment, setEditId, updateComment } from "fond/redux/comments";
import { Comment, CommentImportance, Reply, Store } from "fond/types";
import { makeUuid } from "fond/utils";
import { Editor, ShowMore, SvgIcon } from "fond/widgets";

import Header from "./Header";
import BaseReply from "./Reply";

/**
 * To make sure the tooltip loads in the correct window.document
 * when the component is loaded within a floating window we
 * need to disable the use of a portal.
 */
const tooltipPopperProps = { disablePortal: true };

/**
 * Helper function for rendering the correct icon based
 * on comment type.
 */
export const renderIcon = (commentType?: string): JSX.Element => {
  switch (commentType) {
    case "point":
      return <LocationOn fontSize="small" />;
    case "polygon":
      return <SvgIcon icon="polygon" />;
    case "lineString":
      return <LinearScale fontSize="small" />;
    case "arrowLine":
      return <TrendingFlat fontSize="small" />;
    default:
      return <AddComment fontSize="small" />;
  }
};

/**
 * Helper function for rendering the correct icon based
 * on the importance level.
 */

export const renderImportanceIcon = (importance?: CommentImportance | null, styles?: CSSProperties): JSX.Element => {
  const iconStyle = styles && styles.color ? styles : { color: renderImportanceColor(importance), ...styles };
  switch (importance) {
    case "blocker":
      return <Block style={iconStyle} fontSize="small" />;
    case "critical":
      return <LabelImportant style={{ transform: `rotate(-90deg)`, ...iconStyle }} fontSize="small" />;
    case "major":
      return <DoubleArrow style={{ transform: `rotate(-90deg)`, ...iconStyle }} fontSize="small" />;
    case "minor":
      return <DoubleArrow style={{ transform: `rotate(90deg)`, ...iconStyle }} fontSize="small" />;
    case "trivial":
      return <RadioButtonUnchecked style={iconStyle} fontSize="small" />;
    default:
      return <RemoveCircleOutline style={iconStyle} fontSize="small" />;
  }
};

/**
 * Helper function for rendering the correct color based
 * on the importance level.
 */

export const renderImportanceColor = (importance?: CommentImportance | null): string => {
  switch (importance) {
    case "blocker":
      return red[700];
    case "critical":
      return red[500];
    case "major":
      return orange[600];
    case "minor":
      return blue[700];
    case "trivial":
      return blue[500];
    default:
      return grey[500];
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    scrollMarginTop: "56px",
  },
  content: {
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  primaryIcons: {
    opacity: 0.25,
    "&:hover": {
      opacity: 1,
      color: theme.palette.primary.main,
    },
  },
  resolveIcon: {
    opacity: 0.25,
    "&:hover": {
      opacity: 1,
      color: theme.palette.success.main,
    },
  },
  resolved: {
    opacity: 1,
    color: theme.palette.success.main,
  },
  lastModified: {
    opacity: 0.5,
    fontSize: "0.7rem",
  },
  alert: {
    boxShadow: theme.shadows[1],
  },
  close: {
    padding: theme.spacing(0.5),
  },
  reply: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
  },
  hidden: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "rgba(249, 250, 251, 0.6)",
    borderTop: "1px solid",
    borderColor: theme.palette.divider,
    color: theme.palette.action.active,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  popupHeader: {
    "&:hover": {
      cursor: "move",
    },
  },
}));

const editorStyles = {
  editorContent: {
    maxHeight: 60,
    overflow: "auto",
  },
};

interface IProps {
  /**
   * The comment to be rendered
   */
  comment: Comment;
  /**
   * Flag indicating if the comment is being rendered with
   * the MapPopup
   */
  inPopup?: boolean;
  /**
   * Flag indicating if the header is part of a selected comment
   */
  selected?: boolean;
  /**
   * Callback event for when the user wants to navigate to the comment
   */
  onNavigate?(comment: Comment): { (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void };
  /**
   * Callback event for when the comment is clicked
   */
  onClick?(comment: Comment): { (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void };
}

const CommentComponent = forwardRef<HTMLDivElement, IProps>(({ comment, inPopup = false, selected = false, onClick, onNavigate }: IProps, ref) => {
  const classes = useStyles();
  const dispatch: ThunkDispatch<Store, null, AnyAction> = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const projectId = useSelector((state: Store) => state.project.projectId);
  const [showOptions, setShowOptions] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [key, setKey] = useState(makeUuid());
  const anchorRef = useRef<HTMLButtonElement>(null);
  const commentRef = useRef<HTMLDivElement>(null);
  const [groupMatchStatus, setGroupMatchStatus] = useState<boolean[]>([]);
  const { refetch: refetchPermissions } = useGetPermissionsQuery({ id: projectId, type: "project" });

  useEffect(() => {
    const grouping: boolean[] = [];
    comment.GroupedReplies?.forEach((group: Reply[]) => {
      if (group.length > 0) grouping.push(group?.[0].match);
    }, []);

    setGroupMatchStatus(grouping);
  }, [comment]);

  /**
   * Handles the resolving and unresolving of the comment
   */
  const handleOnResolve = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const commentState = comment.State === "resolved" ? "open" : "resolved";
    const resolvingVerb = comment.State === "resolved" ? "reopening" : "resolving";

    event.stopPropagation();
    dispatch(resolveComment(comment.ID, commentState)).catch((e) => {
      // eslint-disable-next-line no-console
      console.error("Resolve comment Failure", e);
      setError(`There was an issue ${resolvingVerb} your comment, please try again.`);
    });
  };

  /**
   * Handles the submission of the Edit Comment form
   */
  const handleOnCommentSubmit = useCallback((values: IFormData) => {
    return dispatch(
      updateComment({
        rawContent: values.rawContent,
        importance: values.importance || null,
        comment: comment,
        features: comment.Features,
      })
    )
      .then(() => {
        refetchPermissions();
        setIsEditing(false);
      })
      .catch((e) => {
        console.error("Edit Comment Failure", e);
        setError("There was an issue updating your comment, please try again.");
      });
  }, []);

  /**
   * Handles the submission of the Add Reply form
   */
  const handleOnReplySubmit = useCallback((values: IFormData) => {
    return dispatch(
      addReply({
        rawContent: values.rawContent,
        comment: comment,
      })
    )
      .then(() => {
        refetchPermissions();
        setKey(makeUuid());
        commentRef.current?.scrollTo(0, commentRef.current?.scrollHeight);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error("Create Reply Failure", e);
        setError("There was an issue creating your reply, please try again.");
      });
  }, []);

  /**
   * Handles the cancellation of adding a reply
   */
  const handleOnReplyCancel = useCallback(() => {
    setKey(makeUuid());
  }, []);

  /**
   * Handles opening the options menu
   */
  const handleOnMore = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setShowOptions(true);
  };

  const handleOnClose = () => {
    dispatch(unselectComment());
  };

  /**
   * Callback function to allow the user to edit a comments feature
   */
  const handleOnEditFeature = () => {
    dispatch(selectComment({ commentID: comment.ID, features: comment.Features }));
    dispatch(setEditId(comment.ID));
  };

  const handleExpandGroup = (index: number) => () => {
    const newStatus = [...groupMatchStatus];
    newStatus[index] = false;
    setGroupMatchStatus(newStatus);
  };

  const { Creator, CreatedAt, LastModifiedAt, ResolvedBy, State, Type, Importance, RawContent, Replies } = comment;

  if (isEditing) {
    return (
      <Box>
        <DragContext.Consumer>
          {(dragContext) => (
            <Box
              draggable={inPopup}
              onDragStart={inPopup ? dragContext.onDragStart : undefined}
              onDrag={inPopup ? dragContext.onDrag : undefined}
              onDragEnd={inPopup ? dragContext.onDragEnd : undefined}
              className={inPopup ? classes.popupHeader : undefined}
            >
              <Header
                onClick={onClick?.(comment)}
                user={Creator.Email}
                timestamp={CreatedAt}
                commentType={Type}
                importance={Importance}
                rightActions={
                  <Box display="flex" mr={1}>
                    <Tooltip title={State === "open" ? "Resolve comment" : "Reopen comment"} arrow PopperProps={tooltipPopperProps}>
                      <IconButton size="small" onClick={handleOnResolve} data-testid="resolve-button">
                        <Check color="action" className={classNames(classes.resolveIcon, { [classes.resolved]: State === "resolved" })} />
                      </IconButton>
                    </Tooltip>
                    {["point", "polygon", "lineString", "arrowLine"].includes(Type) && (
                      <Tooltip title={Type === "point" ? "View point on map" : "View highlighted area on map"} arrow PopperProps={tooltipPopperProps}>
                        <IconButton size="small" onClick={() => onNavigate?.(comment)} data-testid="directions">
                          <Directions color="action" className={classes.primaryIcons} />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="More options" arrow PopperProps={tooltipPopperProps}>
                      <IconButton size="small" onClick={handleOnMore} ref={anchorRef} data-testid="comment-menu-options">
                        <MoreVert color="action" className={classes.primaryIcons} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                }
                selected={selected}
              />
            </Box>
          )}
        </DragContext.Consumer>
        <Box p={1} pb={0.5}>
          <AddCommentForm
            autoFocus
            initialValues={{ importance: Importance, rawContent: RawContent }}
            hasFeature
            onCancel={() => setIsEditing(false)}
            onSubmit={handleOnCommentSubmit}
            submitText="Update"
            editorStyle={{ editorContent: { minHeight: 150, maxHeight: 246, overflowY: "auto" } }}
            projectId={projectId}
          />
        </Box>
      </Box>
    );
  }

  return (
    <div ref={ref} className={classes.root}>
      <DragContext.Consumer>
        {(dragContext) => (
          <Box
            draggable={inPopup}
            onDragStart={inPopup ? dragContext.onDragStart : undefined}
            onDrag={inPopup ? dragContext.onDrag : undefined}
            onDragEnd={inPopup ? dragContext.onDragEnd : undefined}
            className={inPopup ? classes.popupHeader : undefined}
          >
            <Header
              onClick={onClick?.(comment)}
              user={Creator.Email}
              timestamp={CreatedAt}
              commentType={Type}
              importance={Importance}
              rightActions={
                <Box display="flex" mr={1}>
                  <Tooltip title={State === "open" ? "Resolve comment" : "Reopen comment"} arrow PopperProps={tooltipPopperProps}>
                    <IconButton size="small" onClick={handleOnResolve} data-testid="resolve-button">
                      <Check color="action" className={classNames(classes.resolveIcon, { [classes.resolved]: State === "resolved" })} />
                    </IconButton>
                  </Tooltip>
                  {!inPopup && ["point", "polygon", "lineString", "arrowLine"].includes(Type) && (
                    <Tooltip title={Type === "point" ? "View point on map" : "View highlighted area on map"} arrow PopperProps={tooltipPopperProps}>
                      <IconButton size="small" onClick={onNavigate?.(comment)} data-testid="directions">
                        <Directions color="action" className={classes.primaryIcons} />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="More options" arrow PopperProps={tooltipPopperProps}>
                    <IconButton size="small" onClick={handleOnMore} ref={anchorRef} data-testid="comment-menu-options">
                      <MoreVert color="action" className={classes.primaryIcons} />
                    </IconButton>
                  </Tooltip>
                  {inPopup && (
                    <IconButton size="small" onClick={handleOnClose} data-testid="close-button" style={{ marginLeft: 8 }}>
                      <Close />
                    </IconButton>
                  )}
                </Box>
              }
              selected={selected}
            />
          </Box>
        )}
      </DragContext.Consumer>
      <Box ref={commentRef} display="flex" flexDirection="column" className="customScrollbars" overflow="auto" maxHeight={inPopup ? 300 : "inherit"}>
        <Box className={classes.content}>
          <ShowMore textRowHeight={17} collapsedHeight={34}>
            <Editor readOnly rawContent={RawContent} data-testid="comment-content" />
          </ShowMore>
          {dayjs(CreatedAt).isBefore(dayjs(LastModifiedAt)) && !isEditing && (
            <Box>
              <Typography variant="caption" className={classes.lastModified}>
                {`Last Modified: ${dayjs(LastModifiedAt).format("MMM D, YYYY h:mm A")}`}
              </Typography>
            </Box>
          )}
          {ResolvedBy !== null && (
            <Box>
              <Typography variant="caption" className={classes.lastModified}>
                {`Resolved by: ${ResolvedBy.Email}`}
              </Typography>
            </Box>
          )}
        </Box>

        <CommentMenu
          anchorEl={anchorRef.current}
          comment={comment}
          open={showOptions}
          onClose={() => setShowOptions(false)}
          onEditComment={() => setIsEditing(true)}
          onEditFeature={handleOnEditFeature}
        />

        {comment.GroupedReplies ? (
          <>
            {comment.GroupedReplies.map((replies: Reply[], index: number) => {
              if (groupMatchStatus?.[index]) {
                return (
                  <Tooltip key={`group_${replies[0].ID}`} title="View hidden replies" PopperProps={tooltipPopperProps}>
                    <Box className={classes.hidden} onClick={handleExpandGroup(index)}>
                      <MoreHoriz />
                    </Box>
                  </Tooltip>
                );
              }
              return replies.map((reply) => (
                <Box key={reply.ID}>
                  <BaseReply reply={reply} inPopup={inPopup} />
                </Box>
              ));
            })}
          </>
        ) : (
          <>
            {Replies.map((reply) => (
              <Box key={reply.ID}>
                <BaseReply reply={reply} inPopup={inPopup} />
              </Box>
            ))}
          </>
        )}
      </Box>
      <Box mx={1} mt={1}>
        <AddCommentForm
          key={key}
          // We reset the form on cancel to reset the visited state
          // which is used to show / hide the update / cancel buttons
          onCancel={handleOnReplyCancel}
          onSubmit={handleOnReplySubmit}
          placeholder="Add a reply..."
          submitText="Reply"
          hasFeature
          isReply
          editorStyle={editorStyles}
          projectId={projectId}
        />
      </Box>
      {error && (
        <Snackbar open anchorOrigin={{ vertical: "top", horizontal: "center" }} className={classes.alert}>
          <Alert
            severity="error"
            action={
              <IconButton aria-label="close" color="inherit" className={classes.close} onClick={() => setError(undefined)} size="large">
                <Close />
              </IconButton>
            }
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
});

CommentComponent.displayName = "CommentComponent";

// Memoize the Comment as the cost of unnecessary re-renders is significant
export default memo(CommentComponent);
