import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Add, Addchart, CreateNewFolder, Description, ImageAspectRatio, InsertDriveFileOutlined, UploadFile } from "@mui/icons-material";
import LocationCityIcon from "@mui/icons-material/LocationCityOutlined";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  useTheme,
} from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";

import {
  selectCurrentAccount,
  selectCurrentAllocation,
  selectCurrentSubscription,
  selectFolderById,
  selectFoldersByParentId,
  useCreateProjectMutation,
  useGetAccountModulesQuery,
  useGetAccountSubscriptionsQuery,
} from "fond/api";
import { useFeatureFlag } from "fond/featureFlags";
import mixpanel from "fond/mixpanel";
import { NewFolder } from "fond/projects";
import NewCityPlanner from "fond/projects/CityPlanner/NewCityPlanner";
import { ProjectType, SubscriptionStatus } from "fond/types";
import { useAppSelector } from "fond/utils/hooks";
import { accountModuleCheck, Actions, licenseCheck, permissionCheck, subscriptionCheck } from "fond/utils/permissions";
import { Modal, SubscriptionErrorMessage } from "fond/widgets";

interface RouteParams {
  folderId: string;
}

const NewItemButton: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [popoverAnchorButton, setPopoverAnchorButton] = useState<HTMLButtonElement | null>(null);
  const [currentModal, setCurrentModal] = useState<null | "folder" | "cityPlanner">(null);
  const [plannerProjectErrorModalActive, setPlannerProjectErrorModalActive] = useState(false);
  const { folderId } = useParams<keyof RouteParams>();
  const [createProject, { isLoading }] = useCreateProjectMutation();

  const currentAccount = useAppSelector(selectCurrentAccount);

  const { value: showImportReportOption } = useFeatureFlag("import_report");
  const { value: showCityPlannerOption } = useFeatureFlag("city_planner_foundations");
  const { value: showDesignProjectOption } = useFeatureFlag("design_project");

  const currentAllocation = useAppSelector(selectCurrentAllocation);

  const folder = useAppSelector((state) => (folderId ? selectFolderById(state, folderId) : undefined));
  const siblings = useAppSelector((state) => selectFoldersByParentId(state, folderId ?? null));

  const { data: accountModules } = useGetAccountModulesQuery(currentAccount?.ID ?? skipToken);
  const { data: subscriptions } = useGetAccountSubscriptionsQuery(currentAllocation?.Account.ID ?? skipToken);
  const hasActiveSubscription = subscriptions?.some((subscription) => subscription.Status === SubscriptionStatus.Active);
  const hasValidLicense = licenseCheck(currentAllocation, Actions.PROJECT_ADD) && hasActiveSubscription;
  const canCreateFolder = licenseCheck(currentAllocation, Actions.FOLDER_ADD) && hasActiveSubscription;
  const canAddFolder = (!folder || permissionCheck(folder.Permission.Level, Actions.FOLDER_ADD)) && canCreateFolder;
  const canCreateProject = (!folder || permissionCheck(folder.Permission.Level, Actions.PROJECT_ADD)) && hasValidLicense;
  const canWritePlannerProjects = useAppSelector(
    (state) =>
      currentAccount &&
      hasActiveSubscription &&
      licenseCheck(selectCurrentAllocation(state), Actions.PLANNER_PROJECT_ADD) &&
      subscriptionCheck(selectCurrentSubscription(state, currentAccount.ID), Actions.PLANNER_PROJECT_ADD)
  );
  const canImportReport = accountModuleCheck(accountModules, Actions.REPORT_IMPORT);
  const canCreateCityPlannerProject = (!folder || permissionCheck(folder.Permission.Level, Actions.CITY_PLANNER_ADD)) && hasValidLicense;
  const canWriteCityPlannerProject =
    useAppSelector(
      (state) =>
        currentAccount &&
        hasActiveSubscription &&
        licenseCheck(selectCurrentAllocation(state), Actions.CITY_PLANNER_ADD) &&
        subscriptionCheck(selectCurrentSubscription(state, currentAccount.ID), Actions.CITY_PLANNER_ADD)
    ) && canCreateCityPlannerProject;

  /**
   * Request project creation and navigate to project page on success.
   */
  const createAndOpenProject = async (projectType: ProjectType): Promise<void> => {
    try {
      const project = await createProject({ folderId: folderId, subType: projectType }).unwrap();
      mixpanel.track("New Item", "Project", "Created project", { projectId: project.ID, projectType: projectType });
      navigate(`/project/${project.ID}/`);
    } catch (e) {
      enqueueSnackbar("There was an issue creating this project. Please try again.", { variant: "error" });
      Sentry.captureException(e);
    }
  };

  const onCreateCityPlannerClick = async (): Promise<void> => {
    setCurrentModal("cityPlanner");
    setPopoverAnchorButton(null);
  };

  const onCreateFolderClick = () => {
    setCurrentModal("folder");
    setPopoverAnchorButton(null);
  };

  const isPopoverOpen = !!popoverAnchorButton;
  const mainBlue = theme.palette.primary.main;

  if (!canAddFolder && !canCreateProject) {
    return null;
  }

  return (
    <Box width={160}>
      <Button
        data-testid="new-button"
        fullWidth
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setPopoverAnchorButton(event.currentTarget);
        }}
      >
        New
      </Button>
      <Popover
        id={isPopoverOpen ? "new-item-popover" : undefined}
        open={isPopoverOpen}
        anchorEl={popoverAnchorButton}
        onClose={() => {
          setPopoverAnchorButton(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Paper data-testid="new-item-menu-popover" sx={{ width: 250, maxWidth: "100%" }}>
          <Backdrop open={isLoading} sx={{ position: "absolute", zIndex: "drawer", backgroundColor: "rgba(255,255,255, 0.5)" }}>
            <CircularProgress />
          </Backdrop>
          <MenuList sx={{ color: mainBlue }}>
            <MenuItem data-testid="create-folder-option" aria-disabled={!canAddFolder} disabled={!canAddFolder} onClick={onCreateFolderClick}>
              <ListItemIcon>
                <CreateNewFolder fontSize="small" sx={{ color: mainBlue }} />
              </ListItemIcon>
              <ListItemText>Folder</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem
              data-testid="create-planner-option"
              aria-disabled={!canCreateProject}
              disabled={!canCreateProject}
              onClick={() => {
                if (canWritePlannerProjects) {
                  createAndOpenProject("planner");
                } else {
                  setPlannerProjectErrorModalActive(true);
                }
              }}
            >
              <ListItemIcon>
                <Description fontSize="small" sx={{ color: mainBlue }} />
              </ListItemIcon>
              <ListItemText>Planner Project</ListItemText>
            </MenuItem>
            {showDesignProjectOption && (
              <MenuItem
                data-testid="create-design-option"
                aria-disabled={!canCreateProject}
                disabled={!canCreateProject}
                onClick={() => createAndOpenProject("design")}
              >
                <ListItemIcon>
                  <ImageAspectRatio fontSize="small" color="primary" />
                </ListItemIcon>
                <ListItemText>FOND Design</ListItemText>
              </MenuItem>
            )}
            <MenuItem
              data-testid="create-collaboration-option"
              aria-disabled={!canCreateProject}
              disabled={!canCreateProject}
              onClick={() => createAndOpenProject("collaboration")}
            >
              <ListItemIcon>
                <InsertDriveFileOutlined fontSize="small" sx={{ color: mainBlue }} />
              </ListItemIcon>
              <ListItemText>Collaboration Project</ListItemText>
            </MenuItem>
            {showCityPlannerOption && <Divider />}
            {showCityPlannerOption && (
              <MenuItem
                data-testid="create-city-planner-option"
                aria-disabled={!canWriteCityPlannerProject}
                disabled={!canWriteCityPlannerProject}
                onClick={onCreateCityPlannerClick}
              >
                <ListItemIcon>
                  <LocationCityIcon fontSize="small" sx={{ color: mainBlue }} />
                </ListItemIcon>
                <ListItemText>City Planner Project</ListItemText>
              </MenuItem>
            )}
            <Divider />

            <MenuItem
              data-testid="create-report-option"
              onClick={() => {
                mixpanel.track("New Item", "Report", "Create report menu item clicked");
                navigate({ pathname: "/reports/create", search: folderId && new URLSearchParams({ folderId }).toString() });
              }}
            >
              <ListItemIcon>
                <Addchart fontSize="small" sx={{ color: mainBlue }} />
              </ListItemIcon>
              <ListItemText>Report</ListItemText>
            </MenuItem>

            {showImportReportOption && canImportReport && (
              <MenuItem
                data-testid="import-report-option"
                onClick={() => {
                  mixpanel.track("New Item", "Import Report", "Import report menu item clicked");
                  navigate({ pathname: "/reports/import", search: folderId && new URLSearchParams({ folderId }).toString() });
                }}
              >
                <ListItemIcon>
                  <UploadFile fontSize="small" sx={{ color: mainBlue }} />
                </ListItemIcon>
                <ListItemText>Import Report</ListItemText>
              </MenuItem>
            )}
          </MenuList>
        </Paper>
      </Popover>
      {currentModal === "folder" && <NewFolder onClose={() => setCurrentModal(null)} parentId={folderId} />}
      {currentModal === "cityPlanner" && currentAccount && (
        <NewCityPlanner onClose={() => setCurrentModal(null)} accountId={currentAccount.ID} parentFolder={folder} siblings={siblings} />
      )}
      {plannerProjectErrorModalActive && (
        <Modal
          open
          data-testid="planner-project-error-modal"
          variant="secondary"
          header="Oops..."
          content={<SubscriptionErrorMessage />}
          actions={
            <Button data-testid="back-button" color="primary" onClick={() => setPlannerProjectErrorModalActive(false)}>
              Back
            </Button>
          }
        />
      )}
    </Box>
  );
};

export default NewItemButton;
