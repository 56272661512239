import { DesignLayerIds, ImportStatus, Layer, Project, VersionImportStatus } from "fond/types";
import { enumerate } from "fond/utils";

import { DesignLayers } from "./useGetDesignLayers";

export interface DataPoints {
  project?: Project;
  ugPathLayer?: Layer;
  connectionsLayer?: Layer;
  dropCableLayer?: Layer;
  versionImportStatus?: VersionImportStatus;
}

export const inProgress: string[] = [
  ImportStatus.PENDING_UPLOAD,
  ImportStatus.BUILDING_TILES,
  ImportStatus.CONVERTING,
  ImportStatus.IMPORTING,
  ImportStatus.STARTING,
];

/**
 * Status check for each step that determines if the step is:
 * - Optional
 * - Loading (e.g. waiting on API response)
 * - Completed
 */
const accordionSteps: Array<{
  // Function that determines if the current step should be considered complete
  isComplete(designLayers: DesignLayers): boolean;
  // Function that determines if the current step is processing data.
  isLoading(designLayers: DesignLayers): boolean;
  // Flag indicating if the step is considered optional & can be bypassed
  optional: boolean;
}> = [
  {
    isComplete: (designLayers) => !!designLayers.get(DesignLayerIds.INPUT_LAYER_UG_PATH)?.layer,
    optional: false,
    isLoading: (designLayers) =>
      inProgress.includes(
        designLayers.get(DesignLayerIds.INPUT_LAYER_BOUNDARY)?.status || designLayers.get(DesignLayerIds.INPUT_LAYER_UG_PATH)?.status || ""
      ),
  },
  {
    isComplete: (designLayers) => !!designLayers.get(DesignLayerIds.INPUT_LAYER_CONNECTION_POINTS)?.layer,
    optional: true,
    isLoading: (designLayers) => inProgress.includes(designLayers.get(DesignLayerIds.INPUT_LAYER_CONNECTION_POINTS)?.status || ""),
  },
  {
    isComplete: (designLayers) => !!designLayers.get(DesignLayerIds.OUTPUT_DROP_CABLE)?.layer,
    optional: false,
    isLoading: (designLayers) => inProgress.includes(designLayers.get(DesignLayerIds.OUTPUT_DROP_CABLE)?.status || ""),
  },
];

type ClassSet = { optional: boolean; completed: boolean; current: boolean; disabled: boolean; loading: boolean };

/**
 * For each step in the accordion determine its status.
 */
export const getAccordionStepClassSets = (designLayers: DesignLayers): ClassSet[] => {
  const completed = accordionSteps.map(({ isComplete }) => isComplete(designLayers));
  const loading = accordionSteps.map(({ isLoading }) => isLoading(designLayers));

  return accordionSteps.map(({ optional }, i) => {
    return {
      optional: optional,
      completed: completed[i],
      current: getStartingIndex(designLayers) === i,
      disabled: i > 0 && !completed[i - 1] && (!accordionSteps?.[i - 1]?.optional || !completed?.[i - 2]),
      loading: loading[i],
    };
  });
};

/**
 * Determine the starting index of the accordion based on which steps are completed
 * (move to the first uncompleted step).
 */
export const getStartingIndex = (designLayers: DesignLayers): number => {
  for (let [i, step] of enumerate(accordionSteps)) {
    const thisStepComplete = step.isComplete(designLayers);

    // We ignore optional steps and move on
    if (!step.optional && !thisStepComplete) return i;
  }
  return accordionSteps.length - 1;
};
